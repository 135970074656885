import React, { useEffect, useState } from 'react';

import Loader from '../../assemblies/loader/loader';

import SessionSettings from '../../assemblies/SessionSettings/SessionSettings';
import { DropdownButton } from '../../components/DropdownButton/DropdownButton';
import Button from '../../components/Button/Button';
import Modal from '../../components/Modal/Modal';
import SettingsIcon from '@mui/icons-material/Settings';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

// import './GridDetails.css';

function GridDetails({user, event, schedule}) {
    const [loader, setLoader] = useState({show: false, text: ''});
    
    const [sessionList, setSessionList] = useState(['']);
    const [selectedSession, setSelectedSession] = useState('');

    const [sessionSettingsConfig, setSessionSettingsConfig] = useState({show: false, eventId: 0});  

    const [drivers, setDrivers] = useState([]);
    const [eventConstructors, setEventConstructors] = useState([]);
    const [eventPitStops, setEventPitStops] = useState([]);

    useEffect(() => {
      if(selectedSession !== '') {
        fetchEventGridData();
      }
    }, [event, selectedSession]);

    useEffect(() => {
        console.log('schedule', schedule);
        console.log('event', event);
        setSessionList(schedule.map(s => s.type));
        setSelectedSession(schedule[0].type);
    }, [schedule]);

    const fetchEventGridData = () => {
        let session = schedule.find(s => s.type===selectedSession);
        console.log(session);
        fetchEventDriversData(session);
        fetchEventConstructorsData(session);
        fetchEventPitStopsData(session);
    }

    const fetchEventDriversData = (session) => {
        let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
    
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            eventId: session.id
          })
      };
    
      setLoader({show: true, text: 'Loading Event Drivers...'});
      fetch(apiUrl + `/app/event/sessionDrivers`, requestOptions)
        .then(response => response.json())
        .then(data => {
            setDrivers(data);
            setLoader({show: false});
        })
        .catch((err) => {
            console.log(err);
            setDrivers([]);
            setLoader({show: false});
        });
    }
    
    
    const fetchEventConstructorsData = (session) => {
      let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
  
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          eventId: session.id
        })
      };
  
      setLoader({show: true, text: 'Loading Event Constructors...'});
      fetch(apiUrl + `/app/eventConstructors`, requestOptions)
        .then(response => response.json())
        .then(data => {
            setEventConstructors(data);
            setLoader({show: false});
        })
        .catch((err) => {
            console.log(err);
            setEventConstructors([]);
            setLoader({show: false});
        });
    }
  
  
    const fetchEventPitStopsData = (session) => {
      let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
  
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          eventId: session.id
        })
      };
  
      setLoader({show: true, text: 'Loading Event Pit Stops...'});
      fetch(apiUrl + `/app/event/sessionPitStops`, requestOptions)
        .then(response => response.json())
        .then(data => {
            // if(data!==undefined) {
              setEventPitStops(data);
              // console.log(data.json());
            // }
            setLoader({show: false});
        })
        .catch((err) => {
            console.log(err);
            setLoader({show: false});
        });
    }
  
  
    const updateSessionData = () => {
      let session = schedule.find(s => s.type===selectedSession);

      let apiUrl = process.env.REACT_APP_FORMULA_FANTASY_API;
  
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          eventId: session.id,
          sessionKey: session.sessionKey
        })
      };
  
      setLoader({show: true, text: 'Updating Session Data from OpenF1...'});
      fetch(apiUrl + `/import/updateSessionData`, requestOptions)
        .then(() => {          
            fetchEventGridData();
            setLoader({show: false});
        })
        .catch((err) => {
            console.log(err);
            setLoader({show: false});
        });
    }
    


    const openSessionSettings = () => {
      let session = schedule.find(s => s.type===selectedSession);
      console.log('openSessionSettings', session)
        // setSessionSettingsConfig({
        //     show: true, 
        //     eventId: session.id,
        //     sessionKey: session.sessionKey,
        //     type: session.type,
        //     name: session.name
        // });
    }
    const hideSessionSettings = () => {
        setSessionSettingsConfig({show: false, eventId: 0});
        fetchEventGridData();
    }
    
    const getDriverTime = (bestLapTime, gap) => {
      if(gap===0) {
        return formatDuration(bestLapTime)
      } else {
        return `+${formatDuration(gap)}`
      }
    }

    const formatDuration = (duration) => {        
        let minutes = Math.floor(duration / 60);
        let seconds = (duration % 60).toFixed(3); // Keep the thousandth place

        if (minutes === 0) {
            return `${seconds}`;

        } else {
            let [real, decimal] = seconds.split('.');
            real = real.padStart(2, '0'); // Ensure the real part is two digits
            seconds = `${real}.${decimal}`;
            return `${minutes}:${seconds}`;
        }
    }

    return (
    <div className='event-details'>
        <Loader config={loader}></Loader>

        <SessionSettings config={sessionSettingsConfig} onHide={hideSessionSettings}></SessionSettings>

        <div className='flex justify-content-between'>
          <DropdownButton 
                options={sessionList}
                value={selectedSession}
                clickFunction={(value) => setSelectedSession(value)}
              >
          </DropdownButton>
          {user.isAdmin && (
          <Button variant='icon' onClick={updateSessionData}>
              <CloudDownloadIcon fontSize="small" />
          </Button>
          )}
        </div>

        <div className="dark-card">
            <div className='flex justify-content-between'>
                <h2>Drivers</h2>

                {user.isAdmin && (
                <Button variant='icon' onClick={openSessionSettings}>
                    <SettingsIcon fontSize="small" />
                </Button>
                )}

            </div>
            <table className="table table-layout-auto">
              <thead>
                  <tr>
                      {/* <th>+/-</th> */}
                      <th className='text-align-center'>Pos.</th>
                      <th className='text-align-left'></th>
                      <th>Time</th>
                      <th>Laps</th>
                      {selectedSession === 'Grand Prix' && (
                        <th>Points</th>
                      )}
                  </tr>
              </thead>
              {drivers.length > 0 && (
              <tbody>
                  {drivers.map(d => (
                  <tr>
                      <td className='text-align-center vertical-align-middle'>{d.currentPosition}</td>
                      <td>
                          <div className='flex gap-1'>
                          <img className="answer-image small" src={d.profilePicUrl} alt="answer-img" />
                          <div className='flex flex-column'>
                              <div className='text-align-left single-line'>{d.firstName} <br /> <b>{d.lastName}</b></div>
                              {/* <div className='flex align-items-center' style={{gap: '4px'}}>
                                <img className='constructor-logo-inline' src={d.constructorLogoUrl} alt={d.constructorName}></img>
                                <div className='single-line'>{`${d.constructorName}`}</div>
                              </div> */}
                              <div className='flex gap-05'>
                                {d.fastestLap && (<div className='chip'>Fastest Lap</div>)}
                                {d.dotd && (<div className='chip'>DotD</div>)}
                                {d.status!==null && (<div className='chip'>{d.status}</div>)}
                              </div>
                          </div>
                          </div>
                      </td>
                      <td>{getDriverTime(d.bestLapTime, d.gap)}</td>
                      <td>{d.lapsCompleted}</td>
                      {selectedSession === 'Grand Prix' && (
                      <td>{d.points}</td>          
                      )}
                  </tr>
                  ))}
              </tbody>
              )}
          </table>
        </div>


        <div className="dark-card">
        <table className="table">
            <thead>
                <tr>
                    <th className='text-align-left'>Constructor</th>
                    <th>Pit Stops</th>
                    <th>Points</th>
                </tr>
            </thead>
            {eventConstructors.length > 0 && (
            <tbody>
                {eventConstructors.map(c => (
                <tr>
                    <td>
                        <div className='flex gap-1'>
                        <img className="answer-image small" src={c.constructor.logoUrl} alt={c.constructor.name} />
                        <div className='flex flex-column align-items-start gap-05'>
                            <div>{`${c.constructor.name}`}</div>
                            <div>
                            {c.fastestPitStopPoints>0 && (<div className='chip'>Fastest Pit Stop</div>)}
                            </div>
                        </div>
                        </div>
                    </td>

                    <td>{c.pitStopCount}</td>
                    <td>{c.points}</td>
                </tr>
                ))}
            </tbody>
            )}
        </table>
        </div>

        <div className="dark-card">

          <table className="table">
            <thead>
                <tr>
                    <th className='td-4em'>Lap</th>
                    <th className='td-4em'>Driver</th>
                    <th className='td-4em'>Time</th>
                </tr>
            </thead>
            {eventPitStops.length > 0 && (
            <tbody>
                {eventPitStops.map(row => (
                <tr>
                    <td className='td-4em'>{row.lap}</td>
                    
                    <td className='td-4em'>
                      <div className='flex'>
                        {row.constructor !== null && (
                        <img className="constructor-logo-inline" src={row.constructor.logoUrl} alt={row.constructor.name} />
                        )}
                        {row.driver !== null && (
                        <div>{`${row.driver.lastName}`}</div>
                        )}
                      </div>
                    </td>
                    
                    <td className='td-4em'>{`${row.duration}`}</td>
                </tr>
                ))}
            </tbody>
          )}
          </table>
          
        </div>
    </div>
    );
}

export default GridDetails;