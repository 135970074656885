import React from 'react';
import './Button.css';

// Usage: <Button variant="primary"></Button>
// Variant:
//      (undefined)
//      primary
//      accent
//      warn
/**
 * Button wrapper component.
 * @param {string} variant - Type of Button (undefined), primary, accent, warn, icon, icon-outline, icon-subtle, link, link-subtle, outline-light
 * @param {string} className - Class passthrough
 */
function Button({children, className, ...attributes}) {
    return (
        <button className={`button ${className}`} {...attributes}>
            {children}
        </button>
    )
}

export default Button;